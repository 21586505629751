
import { Vue, Component } from 'vue-property-decorator'
import reservation from '@/services/reservation'
import { CancellationConfirmation, SimpleReservation } from '@/models/dto'
import { CancellationTicketStatus } from '@/utils/enum'
import { Alert } from '@/models/Alert'
import CancellationStarted from '@/components/CancellationStarted.vue'
import CancellationForm from '@/components/CancellationForm.vue'
import alert from '@/store/modules/alert'

@Component({ components: { CancellationStarted, CancellationForm } })
export default class Cancellation extends Vue {
  hash = ''
  reservation: SimpleReservation = null
  isInProgess = false
  submitting = false

  mounted(): void {
    this.hash = this.$route?.params?.hash
    this.getReservation()
  }

  async getReservation(): Promise<void> {
    const response = await reservation.byHashSimple(this.hash)
    this.reservation = response.data.reservation
    this.isInProgess =
      this.reservation?.cancellationTicketStatus !==
      CancellationTicketStatus.Created
  }

  async submit(payload: CancellationConfirmation): Promise<void> {
    this.submitting = true
    const reservationId = this.reservation?.reservationId
    try {
      const response = await reservation.confirmCancellation(
        reservationId,
        payload
      )
      this.isInProgess = response?.data?.successful
    } catch (error) {
      const errorAlert: Alert = {
        text: this.$t('cancellation.ERROR_MESSAGE').toString(),
        color: 'error',
        dismissible: true,
      }
      alert.add(errorAlert)
    }
    this.submitting = false
  }
}
